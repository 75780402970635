<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <img
        src="../assets/icons/knights-logo-full.png"
        class="c-sidebar-brand-full"
        :height="35" 
      />
    </CHeaderBrand>

    <CHeaderNav class="d-md-down-none mr-auto">
    
    </CHeaderNav>
    
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2">
        <p class="m-0">{{ role }}: {{ name }}</p>
      </CHeaderNavItem>
      <!--
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      -->
      <TheHeaderDropdownAccnt/>

    </CHeaderNav>
  </CHeader>
</template>

<script>
  import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
  import encryptStorage from "../controllers/encryptStorage.js";
  import _capitalize from 'lodash/capitalize';
  export default {
    name: 'TheHeader',
    components: {
      TheHeaderDropdownAccnt
    },
    data() {
      return {
          name: encryptStorage.getItem("loggedInUserFirstName") + " " + encryptStorage.getItem("loggedInUserSurname"),
          role: (encryptStorage.getItem("loggedInUserRole") == 'admin') ? "Approver" : _capitalize(encryptStorage.getItem("loggedInUserRole"))
      };
    },
  }
</script>
