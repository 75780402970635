<template>
  <CFooter :fixed="false">
    <div>
      <a href="www.knights-agency.com" target="_blank">Knights Agency</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}}</span>
      <br>
      <span>Version {{uiVersion}}</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">0207 112 8412</span> 
      <br>
      <a href="#">Privacy Policy</a>
    </div>
  </CFooter>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'TheFooter',
  computed: {
      ...mapState(["uiVersion"]),
    },
}
</script>
