export default [  
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Booking']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Booking',
        to: '/bookings',
        icon: 'cil-book',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Courses',
        to: '/courses',
        icon: 'cil-library',
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Users']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Trainers',
        to: '/trainers',
        icon: 'cil-people',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Reports']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Reports',
        to: '/reports',
        icon: 'cil-description',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      }
    ]
  }
]